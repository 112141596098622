import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import SignInView from './views/SignInView'
import * as ROUTES from './constants/routes'
import DashboardView from './views/DashboardView'
import { UserContextWrapper, userDispatch } from './context/User/Context.js'
import { UploadContextWrapper } from './context/Upload/Context'
import Terms from './views/Terms.js'
import Privacy from './views/Privacy.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import ClipLoader from 'react-spinners/ClipLoader.js'
import { ToastProvider, useToasts } from 'react-toast-notifications'
import { setUser } from './context/User/Actions.js'
import Dialogue from './components/Dialogue.js'

const queryClient = new QueryClient()

const AppContainer = () => {
	return (
		<ToastProvider autoDismiss autoDismissTimeout={2000}>
			<QueryClientProvider client={queryClient}>
				<UserContextWrapper>
					<UploadContextWrapper>
						<BrowserRouter>
							<App/>
						</BrowserRouter>
					</UploadContextWrapper>
				</UserContextWrapper>
			</QueryClientProvider>
		</ToastProvider>
	)
}

const App = () => {
	const [loggedIn, setLoggedIn] = useState(null)
	const { addToast } = useToasts()
	const history = useHistory()

	const sessionEnded = () => {
		addToast('Your session has ended', {
			appearance: 'info',
			autoDismiss: true
		})
		history.push('/sign-in')
	}

	useEffect(() => {
		if (ROUTES.AvailableRoutes.indexOf(history.location.pathname) === -1) {
			history.push('/')
		}
	}, [history.location.pathname])

	useEffect(() => {
		const hashKey = '@surgiyo:auth0'
		const hash = localStorage.getItem(hashKey) ? JSON.parse(localStorage.getItem(hashKey)) : window.location.hash
		if (hash) {
			localStorage.setItem(hashKey, JSON.stringify(hash))
			window.webAuth.parseHash({ hash, state: window.webAuthState }, (err, result) => {
				console.log(1, err, result)
				if (err) {
					if (err.errorDescription === 'Unable to configure verification page.') {
						addToast('Enable cross-site tracking to sign in.', {
							appearance: 'error',
							autoDismiss: false
						})
					} else {
						sessionEnded()
					}
					localStorage.clear()
					return setLoggedIn(false)
				}
				window.webAuth.client.userInfo(result.accessToken, function (infoErr, user) {
					if (infoErr) {
						sessionEnded()
						localStorage.removeItem(hashKey)
						return setLoggedIn(false)
					}
					localStorage.setItem('@surgiyo:accessToken', result.accessToken)
					setLoggedIn(true)
					userDispatch(setUser({
						...user,
						id: user.sub.replace('auth0|', '')
					}))
				})
			})
		} else {
			setLoggedIn(false)
		}
	}, [history.location.hash])

	return (
		<>
			<Dialogue/>
			{(loggedIn === true || loggedIn === false)
			  ? <Switch>
					<Route path={ROUTES.DASHBOARD} exact render={(props) => loggedIn ? <DashboardView {...props} /> : <Redirect to={ROUTES.SIGN_IN}/>}/>
					<Route path={ROUTES.SIGN_IN} exact render={(props) => !loggedIn ? <SignInView {...props} /> : <Redirect to={ROUTES.DASHBOARD}/>}/>
					<Route path={ROUTES.Terms} exact component={Terms}/>
					<Route path={ROUTES.Privacy} exact component={Privacy}/>
					<Route path={'/'} exact
						render={() => <Redirect to={ROUTES.DASHBOARD}/>}
					/>
				</Switch>
			  : <ClipLoader color={'black'} loading={true} size={150}/>}
		</>
	)
}

export default AppContainer
