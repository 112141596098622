import React, { createContext, useContext, useReducer } from 'react'
import { initialState, uploadReducer } from './Reducers.js'

export const UploadContext = createContext({
	state: initialState,
	dispatch: () => null
})

export const useUploadContext = () => {
	const { state } = useContext(UploadContext)
	return state
}

export let uploadDispatch

export const UploadContextWrapper = ({ children }) => {
	const [state, dispatch] = useReducer(uploadReducer, initialState)
	uploadDispatch = dispatch
	Object.freeze(uploadDispatch)

	return (
		<UploadContext.Provider value={{ state, dispatch }}>
			{children}
		</UploadContext.Provider>
	)
}
