import axios from 'axios'

const apiClient = axios.create({
	headers: {
		'Content-Type': 'application/json'
	},
	baseURL: process.env.REACT_APP_BASE_URL
})

apiClient.interceptors.request.use(async (config) => {
	config.headers.Authorization = `Bearer ${localStorage.getItem('@surgiyo:accessToken')}`
	return config
}, (error) => {
	return Promise.reject(error)
})

apiClient.interceptors.response.use((response) => {
	return Promise.resolve(response.data)
}, (error) => {
	console.log('HTTP ERROR')
	return Promise.reject(error?.response?.data?.message)
})

export default apiClient
