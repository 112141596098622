import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone-uploader'
import styled from 'styled-components'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { DropzoneWrapper } from '../../styles/Dropzone'
import {
	getSignedUrl, getVideoCover,
	resetUploadedFilesListIntoStore,
	setErrorMessageIntoStore,
	setFilesIntoStore,
	setSubmissionFinishedIntoStore,
	setSuccessFiles,
	setUploadedFilesListIntoStore,
	setUploadStatusIntoStore
} from '../../services/UploadService'
import 'react-dropzone-uploader/dist/styles.css'
import { useUploadContext } from '../../context/Upload/Context'
import Preview from './Preview'
import { IconImages } from '../../styles/icons.js'
import { FlatButton } from '../../styles/common.js'
import { useAuthContext } from '../../context/User/Context.js'
import { setSubmissionFinished } from '../../context/Upload/Actions.js'
import 'firebase/database'

const Upload = () => {
	const {
		uploadStatus,
		uploadedFilesList,
		selectedFiles,
		submissionFinished,
		errorMessage,
		filesUploadProgress
	} = useUploadContext()
	const { user } = useAuthContext()

	const [canUpload, setCanUpload] = useState(true)
	const [uploading, setUploading] = useState(false)
	const [persistError, setPersistError] = useState(false)
	const [warning, setWarning] = useState(null)

	useEffect(() => {
		if (uploadedFilesList.length === selectedFiles.length && selectedFiles.length > 0) {
			setUploadStatusIntoStore('SUCCESS')
			setSuccessFiles([...selectedFiles])
			setSubmissionFinishedIntoStore(true)
			setCanUpload(true)
			setUploading(false)
			for (const f of selectedFiles) {
				f.remove()
			}
			setFilesIntoStore([])
		}
	}, [uploadedFilesList, selectedFiles])

	useEffect(() => {
		if (!!errorMessage && !persistError) {
			setTimeout(() => {
				setErrorMessageIntoStore('')
			}, 4000)
		}
	}, [errorMessage])

	const handleChangeStatus = async (file, status, files) => {
		const GB_LIMIT = 5
		setFilesIntoStore(files)
		try {
			const thumbBlob = await getVideoCover(file.file, 2)
			console.log(thumbBlob)
		} catch (e) {
			if (status !== 'removed') {
				console.log('Failed', e)
				file.remove()
				return setErrorMessageIntoStore(
					'It looks like this file is invalid.'
				)
			}
		}

		if (file.meta.videoHeight === 0 || file.meta.videoWidth === 0) {
			const sizing = 1
		}

		if (bytesToSize(file.meta.size) > GB_LIMIT && status === 'done') {
			const filteredFiles = files.filter(o => bytesToSize(o.meta.size) <= GB_LIMIT)
			setFilesIntoStore(filteredFiles)
			file.remove()
			setErrorMessageIntoStore(
				'The file must be less than 5 GB.'
			)
			return
		}

		const uniqueValues = new Set(
			selectedFiles.map((fileMeta) => fileMeta.file.name)
		)

		if (uniqueValues.size !== selectedFiles.length) {
			const filteredFiles = files.filter(
				(v, i, a) => a.findIndex((t) => t.meta.name === v.meta.name) === i
			)
			setFilesIntoStore(filteredFiles)
			file.remove()
			setErrorMessageIntoStore(
				'Files with the same name cannot be uploaded. Duplicates were removed.'
			)
		}
	}

	const bytesToSize = (bytes) => {
		return bytes / 1024 / 1024 / 1024
	}

	const handleSubmit = async (filesWithMeta) => {
		if (submissionFinished) {
			setCanUpload(true)
			setUploadStatusIntoStore('')
			resetUploadedFilesListIntoStore()
			setFilesIntoStore([])
			filesWithMeta.forEach((file) => file.remove())
			setSubmissionFinishedIntoStore(false)
		} else {
			await Promise.all(filesWithMeta.map((meta, i) => {
				return new Promise(resolve => {
					setTimeout(() => {
						getSignedUrl(meta.file, meta.meta, user.id, i).catch(e => {
							if (e === 'USER_NOT_FOUND') {
								setPersistError(true)
								setErrorMessageIntoStore('Please onboard in the mobile app before uploading a video.')
							} else if (e === 'VERIFY_EMAIL') {
								setPersistError(true)
								setErrorMessageIntoStore('Please verify your email.')
							} else {
								meta.meta.error = e || 'Failed to upload video'
								setWarning('Some files had issues being uploaded')
								setUploadedFilesListIntoStore({ file: meta.file, meta: meta.meta, i })
								resolve()
							}
						})
					}, 1000)
				})
			}))
			setSubmissionFinished(true)
			setSubmissionFinishedIntoStore(true)
			setCanUpload(false)
		}
	}

	const reset = () => {
		resetUploadedFilesListIntoStore()
		setUploadStatusIntoStore('')
		setFilesIntoStore([])
		setSubmissionFinishedIntoStore(false)
		selectedFiles.forEach((file) => file.remove())
		setPersistError(false)
	}

	const upload = () => {
		if (uploadedFilesList.length) {
			reset()
		} else {
			setUploading(true)
			handleSubmit(selectedFiles)
		}
	}

	const totalFiles = filesUploadProgress.length
	const totalPercent = totalFiles > 0 ? (filesUploadProgress.map(o => o.progress).reduce((total, progress) => (total + progress)) / totalFiles) : 0
	const disabled = uploadStatus === 'SUCCESS' || uploading

	const inputContent = () => {
		return uploadStatus === 'SUCCESS'
			? <DropContent>
				<IconImages type={warning ? 'error' : 'success'}/>
				<div className="success-text">{warning ? 'Oops' : 'Success'}!</div>
				<div className="drop-text">
					{warning || 'Files were successfully uploaded'}
				</div>
			</DropContent>
			: errorMessage
				? <DropContent>
					<IconImages type={'error'}/>
					<div className="success-text">Oops! Something went wrong</div>
					<div className="drop-text">
						{errorMessage}
					</div>
				</DropContent>
				: (totalPercent === 100 && uploading)
					? <DropContent>
						<RotatingBar strokeWidth={2} value={60} text={''}/>
						<div className="drop-text">
							Video Uploaded <br/>
							{uploadStatus}
						</div>
					</DropContent>
					: uploading
						? <DropContent>
							{totalPercent === 0
						  ? <RotatingBar strokeWidth={2} value={60} text={''}/>
						  : <CircularProgressbar strokeWidth={2} value={totalPercent}
																	 text={`${totalPercent.toFixed(0)}%`}/>}
							{totalPercent === 0 && <div className="drop-text">
							Preparing Upload
							</div>}
							{totalPercent > 0 && <div className="drop-text">
							Video(s) uploading <br/>
							Do not navigate away from this page.
							</div>}
						</DropContent>
						: <DropContent>
							<IconImages type={'dropImage'}/>
							<div className="drop-text">
							Drag & drop file here <br/>
							or Click to upload
							</div>
						</DropContent>
	}

	const uploadVideosButton = (title, style) => (
		<>
			{!uploading && <div style={style}>
				<FlatButton disabled={uploadedFilesList.length === 0 && (selectedFiles.length === 0 || !canUpload)}
					onClick={upload}>
					{title}
				</FlatButton>
			</div>}
		</>
	)

	return (
		<DropzoneWrapper>
			{!uploading && <UploadList>
				{selectedFiles.map((fileWithMeta, i) => <Preview fileWithMeta={fileWithMeta} meta={fileWithMeta.meta}
																												 index={i}/>)}
			</UploadList>}
			{uploadedFilesList.length > 0 &&
			<div className={'top-upload-btn'}>
			  {uploadVideosButton('New Upload', {})}
			</div>
			}
			<Dropzone
				onChangeStatus={handleChangeStatus}
				onSubmit={handleSubmit}
				disabled={disabled}
				styles={{
				  preview: { display: 'none' }
				}}
				inputContent={inputContent()}
				inputWithFilesContent={inputContent()}
				accept="video/*, .ogg"
			/>
			{uploadedFilesList.length === 0 && uploadVideosButton('Upload Videos', { marginTop: 34 })}

			{uploadedFilesList.length > 0 && <UploadList style={{ marginTop: 30 }}>
				{uploadedFilesList.map((fileWithMeta, i) => <Preview date fileWithMeta={fileWithMeta} meta={fileWithMeta.meta}
																														 index={i}/>)}
			</UploadList>}
		</DropzoneWrapper>
	)
}

export default Upload

const DropContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.drop-text {
		text-align: center;
		margin-top: 14px;
		font-family: Inter, sans-serif;
		font-size: 18px;
		color: #333333;
		line-height: 26px;
		font-weight: 400;
	}

	.success-text {
		font-family: Inter, sans-serif;
		font-size: 24px;
		color: white;
		margin-top: 12px;
		line-height: 26px;
		font-weight: 400;
	}
`

const UploadList = styled.div`
	width: 100%;
	overflow-y: scroll;
`

const RotatingBar = styled(CircularProgressbar)`
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
`
