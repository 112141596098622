import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 89px 12px 12px 12px;

  @media only screen and (max-height: 830px) {
    padding: 12px 12px 12px 12px;
  }

  @media only screen and (max-width: 460px) {
    padding: 0;
  }
`

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  flex: 1;
  margin-top: 185px;
  width: 100%;
`

export const FormTitle = styled.div`
  font-size: 76px;
  line-height: 46px;
  font-weight: 500;

  color: white;
  margin-bottom: 26px;
  margin-top: -20px;

  font-family: FletchaMM, sans-serif;
`

export const FormSubtitle = styled.div`
  font-size: 36px;
  font-weight: 500;

  color: white;
  margin-bottom: 20px;

  font-family: FletchaMM, sans-serif;
`

export const IconInput = styled('div')`
  position: relative;

  img {
    position: absolute;
    top: ${(props) => props.top || 19}px;
    left: 18px;
  }

  input {
    padding-left: 40px;
  }

  width: 400px;
  @media only screen and (max-width: 450px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`

export const InputCoverError = styled.div`
  width: ${props => props.fluid ? '100%' : 80 + 'px'};
  height: 56px;
  position: absolute;
  background-color: white;
  border: thin solid #DE5D5A;
  border-radius: 13px;
  display: flex;
  align-items: center;
  color: #DE5D5A;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;


  @media only screen and (max-width: 450px) {
    width: ${props => !props.fluid && {
		width: '80%',
		marginLeft: 'auto',
		marginRight: 'auto'
	}}
  }
`

export const FormInput = styled('input')`
  background: #FBFBFB;
  border: 1px solid #CDD4D4;
  box-sizing: border-box;
  border-radius: 13px;
  width: ${props => props.fluid ? '100%' : 80 + 'px'};
  height: 56px;
  margin-bottom: 16px;
  padding-left: 12px;
  font-family: Inter, sans-serif;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 450px) {
    width: ${props => !props.fluid && {
		width: '80%',
		marginLeft: 'auto',
		marginRight: 'auto'
	}}
  }

`

export const FormLabel = styled.label`
  margin-top: 15px;
`

export const Button = styled.button`
  margin-top: 18px;
  background: linear-gradient(262.43deg, #244F55 40.78%, #245057 72.42%, #3EC9B1 125.15%);
  height: 56px;
  border-radius: 10px;
  width: 400px;
  cursor: pointer;

  font-family: FletchaMB, sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  border: none;
  
  margin-bottom: 30px;

  filter: drop-shadow(0px 12px 32px rgba(36, 79, 85, 0.28));

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 450px) {
    width: 80%;
  }
`

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

`

export const SplitView = styled.div`

  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export const FlatButton = styled.button`
  border-radius: 10px;
  height: 56px;
  width: 210px;
  text-transform: uppercase;
  color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.5)' : 'white'};
  border: none;
  background-color: #244F55;
  bottom: 22.78%;

  font-family: FletchaMB, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.05em;
  cursor: pointer;
`
