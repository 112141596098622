import styled from 'styled-components'

export const AuthLogo = styled.img`
  position: absolute;
  width: 300px;
  top: -90px;
  right: -90px;
`

export const AuthGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 48%;
  top: 0;
  border-radius: 24px 24px 70px 70px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
`

export const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  height: 722px;

  background: #FFFFFF;
  box-shadow: 0px 30px 120px rgba(0, 0, 0, 0.18);
  border-radius: 24px;
  max-width: 598px;
  width: 100%;

  @media only screen and (max-width: 460px) {
    height: 100vh;
    border-radius: 0;
    .gradient {
      border-radius: -0 0 70px 70px;
    }
  }

`

export const ForgotPasswordText = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #093034;
  margin-top: 25px;

  &:hover {
    cursor: default;
    text-decoration: underline;
  }
`

export const AuthNav = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: rgba(9, 48, 52, 0.6);
  margin-bottom: 29px;
  cursor: default;

  span {
    color: #3EC9B1;

    &:hover {
      text-decoration: underline;
    }
  }
`
