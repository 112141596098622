import axios from 'axios'
import moment from 'moment'
import apiClient from '../helpers/apiClient.js'
import {
	resetUploadedFilesList,
	setErrorMessage,
	setFiles,
	setFilesUploadProgress,
	setIsFileUploading,
	setSubmissionFinished,
	setUploadedFiles,
	setUploadedFilesList,
	setUploadStatus
} from '../context/Upload/Actions.js'
import { uploadDispatch } from '../context/Upload/Context.js'
import 'firebase/auth'
import { setDialogueMessage } from '../context/User/Actions.js'
import { userDispatch } from '../context/User/Context.js'
import 'firebase/database'

export function setFilesIntoStore (files) {
	try {
		const action = setFiles(files)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setFilesUploadProgressIntoStore (
	filesUploadProgress
) {
	try {
		const action = setFilesUploadProgress(filesUploadProgress)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setUploadStatusIntoStore (uploadStatus) {
	try {
		const action = setUploadStatus(uploadStatus)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setSuccessFiles (files) {
	try {
		const action = setUploadedFiles(files)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setIsFileUploadingIntoStore (isFileUploading) {
	try {
		const action = setIsFileUploading(isFileUploading)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setUploadedFilesListIntoStore (uploadedFile) {
	try {
		const action = setUploadedFilesList(uploadedFile)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function resetUploadedFilesListIntoStore () {
	try {
		const action = resetUploadedFilesList()
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setSubmissionFinishedIntoStore (
	submissionFinished
) {
	try {
		const action = setSubmissionFinished(submissionFinished)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setErrorMessageIntoStore (errorMessage) {
	try {
		const action = setErrorMessage(errorMessage)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

const bodyParameters = (selectedFile, meta, userId) => {
	const ext = selectedFile.name.substr(selectedFile.name.lastIndexOf('.'))
	const id = moment().valueOf().toString()
	return {
		filename: (selectedFile.lastModified || moment().valueOf().toString()) + '_' + userId + '_' + id + '_' + `${meta.videoWidth || 200}x${meta.videoHeight || 200}_` + ext,
		type: selectedFile.type
	}
}

export const getSignedUrl = (selectedFile, meta, userId, index) => {
	setIsFileUploadingIntoStore(true)
	const body = bodyParameters(selectedFile, meta, userId)
	return apiClient
		.post(
			'storage/get_signed_url',
			body
		)
		.then((response) => {
			uploadFile(response.data.url, selectedFile, body.filename, meta)
			if (meta.videoWidth === 0 || meta.videoHeight === 0) {
				return
			}
			return uploadThumbnail(response.data.thumbUrl, selectedFile, body.filename)
		})
}

export const uploadFile = (signedUrl, selectedFile, filename, meta) => {
	const blob = selectedFile.slice(0, selectedFile.size, selectedFile.type)
	const newFile = new File([blob], filename, { type: selectedFile.type })

	const config = {
		headers: { 'Content-Type': newFile.type },
		onUploadProgress: (progressEvent) => {
			const progress = progressEvent.loaded
			const percent = Math.round((progress * 100) / progressEvent.total)
			const fileProgress = { file: newFile, progress: percent }
			setFilesUploadProgressIntoStore(fileProgress)
		}
	}

	axios
		.put(signedUrl, newFile, config)
		.then(() => {
			const finish = () => {
				const uid = newFile.name.split('_')[2]
				setIsFileUploadingIntoStore(false)
				setUploadedFilesListIntoStore({ file: newFile, meta, uid })
				setSubmissionFinished(true)
				setUploadStatusIntoStore(null)
			}
			finish()
			// setUploadStatusIntoStore("Compressing...\n\n This may take a few minutes")
			// firebase.database().ref("/videoReady/" + filename.replace(/\./g, "")).on("value", event => {
			// 	if (event.exists() && event.val() === true) {
			// 		finish()
			// 	}
			// })
		})
		.catch((e) => {
			console.log(e)
			setUploadStatusIntoStore('Something went wrong. Please try again.')
		})
}

export const uploadThumbnail = async (signedUrl, selectedFile, filename) => {
	try {
		const thumbBlob = await getVideoCover(selectedFile, 2)
		const newFile = new File([thumbBlob], filename, { type: 'image/jpeg' })

		axios
			.put(signedUrl, newFile, {
				headers: { 'Content-Type': 'image/jpeg' }
			})
			.catch((e) => {
				console.log(e)
			})
	} catch (e) {
		const action = setDialogueMessage('There was an error uploading your thumbnail.')
		userDispatch(action)
	}
}

export function getVideoCover (file, seekTo = 0.0) {
	return new Promise((resolve, reject) => {
		// load the file to a video player
		const videoPlayer = document.createElement('video')
		videoPlayer.setAttribute('src', URL.createObjectURL(file))
		videoPlayer.load()
		videoPlayer.addEventListener('error', (ex) => {
			console.log('Failed video loading', ex)
			reject('error when loading video file', ex)
		})
		// load metadata of the video to get video duration and dimensions
		videoPlayer.addEventListener('loadedmetadata', () => {
			// seek to user defined timestamp (in seconds) if possible
			if (videoPlayer.duration < seekTo) {
				reject('video is too short.')
				return
			}
			// delay seeking or else 'seeked' event won't fire on Safari
			setTimeout(() => {
				videoPlayer.currentTime = seekTo
			}, 200)
			// extract video thumbnail once seeking is complete
			videoPlayer.addEventListener('seeked', () => {
				console.log('video is now paused at %ss.', seekTo)
				// define a canvas to have the same dimension as the video
				const canvas = document.createElement('canvas')
				canvas.width = videoPlayer.videoWidth || 400
				canvas.height = videoPlayer.videoHeight || 300
				// draw the video frame to canvas
				const ctx = canvas.getContext('2d')
				ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
				// return the canvas image as a blob
				ctx.canvas.toBlob(
					blob => {
						resolve(blob)
					},
					'image/jpeg',
					0.75 /* quality */
				)
			})
		})
	})
}
