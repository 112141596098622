import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useUploadContext } from '../../context/Upload/Context'
import { IconImages } from '../../styles/icons.js'
import VideoPlayer from 'simple-react-video-thumbnail-j'
import DateUpdater from '../DateUpdater.js'
import moment from 'moment'
import { patchUploadedFiles } from '../../context/Upload/Actions.js'
import { usePatchVideo } from '../../context/hooks/upload.hook.js'

const FileDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: thin solid #333333;
  border-radius: 10px;
  height: 67px;
  min-height: 67px;
  font-size: 14px;
  width: 90%;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;

  .trash {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-right: 22px;
    margin-left: auto;
  }

  .video-player {
    margin-left: 22px;
  }

  .video-player, video, .video-player__thumbnail-container img, .video-player__thumbnail-container {
    height: 30px;
    width: 30px;
    border-radius: 7px;

    img {
      object-fit: cover;
    }
  }

  .video-player__icon-container {
    display: none;
  }

  margin-bottom: 16px;
`

const FileName = styled.span`
  overflow-wrap: break-word;
  margin-left: 22px;
`

const Preview = ({ meta, fileWithMeta, date, index }) => {
	const [fileData, setFileData] = useState({
		localUrl: '',
		thumb: ''
	})
	const { name } = meta
	const { filesUploadProgress, submissionFinished } = useUploadContext()
	const [patching, setPatching] = useState(false)
	const patch = usePatchVideo()
	const videoPlayerRef = useCallback(ref => {

	}, [])

	const updateDate = async (date) => {
		setPatching(true)
		try {
			const formattedDate = moment(date).format()
			await patch.mutateAsync({
				operationDate: moment(date).valueOf().toString(),
				videoId: fileWithMeta.uid
			})
			patchUploadedFiles(index, {
				operationDate: formattedDate
			})
		} catch (e) {
			console.log(e)
		}
		setPatching(false)
	}

	useEffect(() => {
		if (fileWithMeta) {
			const u = (window.URL || window.webkitURL)
			if (u) {
				setFileData({
					thumb: '',
					localUrl: u.createObjectURL(fileWithMeta.file)
				})
			}
		}
	}, [fileWithMeta])

	const remove = () => {
		fileWithMeta.remove()
	}

	const getDate = () => {
		return fileWithMeta.operationDate || meta.lastModifiedDate
	}

	return (
		<FileDetails>
			{fileData.localUrl && <VideoPlayer
				videoUrl={fileData.localUrl}
				snapshotAt={3}
				ref={videoPlayerRef}
			/>}
			<FileName>{name}</FileName>
			{meta.error
			  ? <Failure>
            File upload failed
					<IconImages type={'error'} style={{ width: 28 }}/>
				</Failure>
			  : date
			    ? <DateUpdater
						maxDate={new Date()}
						loading={patching} updateDate={updateDate} date={getDate()}/>
			    : null
			}
			{!submissionFinished && (
				<IconImages className="trash" onClick={remove} type={'trash'}/>
			)}
		</FileDetails>
	)
}

export default Preview

const Failure = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  max-width: 165px;
  width: 150px;
  height: 37px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: 20px;
  color: #B6B6B6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 10px;
  font-family: Inter, sans-serif;
`
