import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import validator from 'validator'
import { Section } from '../styles/common.js'
import * as ROUTES from '../constants/routes.js'
import { signIn, signInApple, signInDoximity, signInLinkedin } from '../services/AuthService.js'
import LoginForm from '../components/LoginForm.js'

const SignInView = () => {
	const history = useHistory()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [isValid, setIsValid] = useState(true)
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(null)

	const handleRedirect = () => {
		history.push(ROUTES.DASHBOARD)
	}

	const onLinkedin = async () => {
		setLoading('linkedin')
		await signInLinkedin(redirectToDashboard).finally(() => setLoading(null))
	}

	const onApple = async () => {
		setLoading('apple')
		await signInApple(redirectToDashboard).finally(() => setLoading(null))
	}

	const onDoximity = async () => {
		setLoading('doximity')
		await signInDoximity(redirectToDashboard).finally(() => setLoading(null))
	}

	useEffect(() => {
		const firebaseUserUID = localStorage.getItem('firebaseUserUID')
		const token = localStorage.getItem('token')
		if (firebaseUserUID && token) {
			handleRedirect()
		}
	})

	const redirectToDashboard = (canRedirect, errorMessage) => {
		setErrorMessage(errorMessage)
		if (canRedirect) {
			handleRedirect()
		}
	}

	const signInWithEmailAndPassword = async () => {
		setLoading('login')
		await signIn(email, password, redirectToDashboard).finally(() => setLoading(null))
	}

	const onChange = (event) => {
		const { name, value } = event.currentTarget

		switch (name) {
		case 'email':
			setEmail(value)
			if (validator.isEmail(value)) {
				setIsValid(true)
			} else {
				setIsValid(false)
			}
			break
		case 'password':
			setPassword(value)
			break
		}
	}

	return (
		<Section>
			<LoginForm
				onApple={onApple}
				onLinkedin={onLinkedin}
				loading={loading}
				email={email}
				password={password}
				onChange={onChange}
				onSubmit={signInWithEmailAndPassword}
				disabled={false}
				errorMessage={errorMessage}
			/>
		</Section>
	)
}

export default SignInView
