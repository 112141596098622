import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import firebase from "firebase/app"
import auth0 from "auth0-js"

import { firebaseConfig } from "./firebaseConfig.js"
import AppContainer from "./App.js"

firebase.initializeApp(firebaseConfig)
console.log(">>>", process.env.REACT_APP_AUTH0_DOMAIN)
const webAuth = new auth0.WebAuth({
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
	responseType: "token",
	scope: "openid profile email",
	redirectUri: process.env.REACT_APP_HOST + "/sign-in",
	state: "rndm$tat3val",
	audience: `https://${process.env.REACT_APP_AUTH0_AUDIENCE_DOMAIN}/api/v2/`
})

window.webAuth = webAuth
window.webAuthState = "rndm$tat3val"

ReactDOM.render(
	<React.StrictMode>
		<AppContainer />
	</React.StrictMode>,
	document.getElementById("root")
)
