import {
	PATCH_UPLOADED_FILES,
	RESET_UPLOADED_FILES_LIST,
	SET_ERROR_MESSAGE,
	SET_FILES,
	SET_FILES_UPLOAD_PROGRESS,
	SET_IS_FILE_UPLOADING,
	SET_SUBMISSION_FINISHED,
	SET_UPLOAD_FILES,
	SET_UPLOAD_STATUS,
	SET_UPLOADED_FILES_LIST
} from './Actions'

export const initialState = {
	selectedFiles: [],
	filesUploadProgress: [],
	uploadStatus: '',
	isFileUploading: false,
	uploadedFilesList: [],
	submissionFinished: false,
	errorMessage: '',
	sessionFiles: []
}

export function uploadReducer (
	state,
	action
) {
	switch (action.type) {
	case SET_FILES: {
		const selectedFiles = action.payload
		return { ...state, selectedFiles }
	}
	case SET_FILES_UPLOAD_PROGRESS: {
		const filesUploadProgress = action.payload

		const filesUploadProgressState = Object.assign(
			[],
			state.filesUploadProgress
		)

		const alreadyOnListElement = filesUploadProgressState.find(
			(el) => el?.file.name === filesUploadProgress.file.name
		)

		let dataToUpdate = state.filesUploadProgress

		if (alreadyOnListElement) {
			const alreadyonListElementIndex = state.filesUploadProgress.findIndex(
				(el) => el.file.name === alreadyOnListElement.file.name
			)

			dataToUpdate.splice(alreadyonListElementIndex, 1, filesUploadProgress)
		} else {
			dataToUpdate = state.filesUploadProgress.concat(filesUploadProgress)
		}

		return { ...state, filesUploadProgress: dataToUpdate }
	}
	case SET_UPLOAD_STATUS: {
		const uploadStatus = action.payload
		return { ...state, uploadStatus }
	}
	case SET_IS_FILE_UPLOADING: {
		const isFileUploading = action.payload
		return { ...state, isFileUploading }
	}
	case SET_UPLOADED_FILES_LIST: {
		const uploadedFile = action.payload
		return {
			...state,
			uploadedFilesList: state.uploadedFilesList.concat(uploadedFile)
		}
	}
	case RESET_UPLOADED_FILES_LIST: {
		return {
			...state,
			uploadedFilesList: [],
			sessionFiles: [],
			filesUploadProgress: []
		}
	}
	case SET_SUBMISSION_FINISHED: {
		const submissionFinished = action.payload
		return { ...state, submissionFinished }
	}
	case SET_ERROR_MESSAGE: {
		const errorMessage = action.payload
		return { ...state, errorMessage }
	}
	case PATCH_UPLOADED_FILES: {
		const files = [...state.uploadedFilesList]
		files[action.payload.index] = {
			...files[action.payload.index],
			...action.payload.data
		}
		return {
			...state,
			uploadedFilesList: files
		}
	}
	case SET_UPLOAD_FILES: {
		return {
			...state,
			sessionFiles: action.payload
		}
	}
	default:
		return state
	}
}
