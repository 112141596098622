import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, FormContainer, FormInput, FormSubtitle, FormTitle, IconInput } from '../styles/common'
import Logo from '../assets/logo.svg'
import { AuthForm, AuthGradient, AuthLogo, AuthNav } from '../styles/Auth'
import { IconImages } from '../styles/icons'
import Loader from './Loader'
import Gradient from '../assets/images/gradient.png'
import Apple from '../assets/icons/apple.svg'
import Linkedin from '../assets/icons/linkedin.svg'

const Error = styled.span`
  font-size: 14px;
  color: red;
`

const LoginForm = ({
	email,
	password,
	onChange,
	onSubmit,
	disabled,
	errorMessage,
	loading,
	onLinkedin,
	onApple
}) => {
	const [showPassword, setShowPassword] = useState(false)
	const [tempError, setTempError] = useState({
		email: '',
		password: ''
	})

	const onKeyUpValue = (ev) => {
		if (!password || !email) {
			return
		}
		if (ev.code === 'Enter') {
			onSubmit()
		}
	}

	return (
		<AuthForm>
			<AuthGradient className={'gradient'} style={{ backgroundImage: `url(${Gradient})` }}/>
			<AuthLogo src={Logo} alt={'Logo'}/>
			<FormContainer>
				<FormTitle>
          Surgiyo
				</FormTitle>
				<FormSubtitle>
          Sign In
				</FormSubtitle>
				<IconInput top={23}>
					<IconImages type={'email'}/>
					{/* <InputCoverError fluid>Invalid email</InputCoverError> */}
					<FormInput
						onKeyUp={onKeyUpValue}
						type="email"
						name="email"
						value={email}
						fluid
						id="email"
						placeholder={'Email'}
						onChange={onChange}
					/>
				</IconInput>
				<IconInput>
					<IconImages type={'lock'}/>
					<FormInput
						onKeyUp={onKeyUpValue}
						type={showPassword ? 'text' : 'password'}
						name="password"
						value={password}
						fluid
						id="password"
						placeholder={'Password'}
						onChange={onChange}
					/>
					{showPassword
					  ? <IconImages type={'eyeSlash'} style={{ top: 23, left: 'unset', right: 17, cursor: 'pointer' }}
							onClick={() => setShowPassword(!showPassword)}/>
					  : <IconImages type={'eye'} style={{ top: 24, left: 'unset', right: 18, cursor: 'pointer' }}
							onClick={() => setShowPassword(!showPassword)}/>}
				</IconInput>
				<Error>{errorMessage}</Error>
				<Button type="button" disabled={!!loading || disabled} onClick={onSubmit} style={{ marginBottom: 24 }}>
					{loading !== 'login' ? 'Sign in' : <Loader size={30}/>}
				</Button>

				<HrLine><span>Or</span></HrLine>

				<SocialButtons>
					{/* <button type="button" disabled={!!loading} onClick={onDoximity} style={{background: '#1276d3'}}> */}
					{/*  {loading !== 'doximity' && <img src={Doximity} />} */}
					{/*  {loading !== 'doximity' ? 'Doximity' : <Loader size={30}/>} */}
					{/* </button> */}
					<button type="button" disabled={!!loading} style={{ background: '#000000' }}
						onClick={onApple}>
						{loading !== 'apple' && <img style={{ position: 'relative', top: 1.2 }} src={Apple} />}
						{loading !== 'apple' ? 'Apple' : <Loader size={30}/>}
					</button>
					<button type="button" disabled={!!loading} style={{ background: '#0073b1' }}
						onClick={onLinkedin}>
						{loading !== 'linkedin' && <img src={Linkedin} />}
						{loading !== 'linkedin' ? 'Linkedin' : <Loader size={30}/>}
					</button>
				</SocialButtons>

				{/* <ForgotPasswordText style={{opacity: 0}}>Forgot Password</ForgotPasswordText> */}
			</FormContainer>

			{/* eslint-disable-next-line react/no-unescaped-entities */}
			<AuthNav style={{ opacity: 0 }}>Don't have an account? <span>Sign up<IconImages type={'chevRight'} style={{
			  position: 'relative',
			  top: 1,
			  left: 6
			}}/></span></AuthNav>
		</AuthForm>
	)
}

export default LoginForm

const HrLine = styled.span`
  width: 400px;
  text-align: center;
  border-bottom: 1px solid rgb(9, 48, 52, 0.45);
  line-height: 0.1em;
  margin: 10px 0 20px;

  @media only screen and (max-width: 450px) {
    width: 80%;
  }

  span {
    background: #fff;
    color: #093034;
    padding: 0 10px;
  }
`

const SocialButtons = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  button {
    width: 195px;
    height: 46px;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: Inter, sans-serif;
    cursor: pointer;
    img {
      position: relative;
      top: 3px;
      right: 6px;
    }
  }
  
  button:first-child {
    margin-right: 5px;
    background: #4DA6EA;
  }
  
  button:last-child {
    margin-left: 5px;
    background: #3176A2;
  }

`
