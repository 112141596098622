import React from 'react'
import email from '../assets/icons/email.svg'
import eye from '../assets/icons/eye.svg'
import lock from '../assets/icons/lock.svg'
import chevRight from '../assets/icons/chev-right.svg'
import eyeSlash from '../assets/icons/eye-slash.svg'
import dropImage from '../assets/icons/drop-image.svg'
import trash from '../assets/icons/trash.svg'
import success from '../assets/icons/success.svg'
import error from '../assets/icons/error.svg'
import close from '../assets/icons/close.svg'
import warning from '../assets/icons/warning.svg'

export const IconImages = (props) => {
	const icon = () => {
		switch (props.type) {
		case 'eye':
			return eye
		case 'email':
			return email
		case 'lock':
			return lock
		case 'chevRight':
			return chevRight
		case 'eyeSlash':
			return eyeSlash
		case 'dropImage':
			return dropImage
		case 'trash':
			return trash
		case 'success':
			return success
		case 'error':
			return error
		case 'close':
			return close
		case 'warning':
			return warning
		}
	}

	return (
		<img src={icon()} alt={'E'} {...props} />
	)
}
